import {
  createScriptTag,
  fetchScriptData,
  getExistingScripts,
  getPrivacySingletonObject,
  isValidObject,
  isValidObjectButNotArray,
  isValidString,
  logError,
  logWarning,
  memoizedRetryWithTimeout,
  objectValuesSortedByKey,
  validateScript,
  reportScriptSupplierError,
} from '@americanexpress/dxt-script-supplier-utils';

/**
 * Notice this URL could reference the E1, E2 or E3 instances of One Data. The reason it is
 * hard-coded despite that fact is so the maintainers of Script Supplier can have legitimate
 * E1, E2 & E3 environments for development work. End-users & other developers will only ever
 * interact with the E3 script registry (on the E3 instance of One Data). So, a maintainer of this
 * package would set the URL below to the appropriate environment depending on
 * whether they are actively developing Script Supplier, making a build to distribute to
 * quality assurance (or other internal stakeholders) or making a production build to distribute
 * abroad & eventually reach end-users.
 */
const SCRIPT_REGISTRY_URL = 'https://functions.americanexpress.com/ReadScriptRegistry.v1';

const UCM_SCRIPT_REGEX = /^https:\/\/(cdaas-dev\.americanexpress\.com|q?www\.aexp-static\.com)(\/cdaas)?(\/one)?(\/akamai)?\/user-consent-management\/(ucm\/v)?\d+\.\d+\.\d+-?\d*\/ucm\.js(\?|$)/i;
const HELPER_SCRIPT_REGEX = /^https:\/\/(cdaas-dev\.americanexpress\.com|q?www\.aexp-static\.com)(\/cdaas)?\/one\/dxt-script-supplier-helper\/\d+\.\d+\.\d+-?\d*\/dxt-script-supplier-helper\.js(\?|$)/i;

const PRESET_UPDATE_EVENT = 'amex-script-supplier-preset-update';

function scriptPreviouslyLoaded(existingScripts, src) {
  if (UCM_SCRIPT_REGEX.test(src?.trim())) {
    return [...existingScripts].some((script) => UCM_SCRIPT_REGEX.test(script.src?.trim()));
  }

  return [...existingScripts].some((script) => HELPER_SCRIPT_REGEX.test(script.src?.trim()));
}

const loadScriptOnPage = ({
  existingScripts,
  scriptAttributes,
}) => {
  const isUCMScript = UCM_SCRIPT_REGEX.test(scriptAttributes.src?.trim());
  const isHelperScript = HELPER_SCRIPT_REGEX.test(scriptAttributes.src?.trim());
  const previouslyLoaded = scriptPreviouslyLoaded(existingScripts, scriptAttributes.src);

  if (!previouslyLoaded && (isUCMScript || isHelperScript)) {
    const scriptTag = createScriptTag(scriptAttributes);

    document.head.appendChild(scriptTag);
  }
};

/** Returns a Promise */
function processScript(args) {
  let scriptNameForLog;
  let scriptIndexForLog;
  const pageLocale = window?.UCMPageLocale || window?.scriptSupplierPageLocale;

  const handleError = (error, scriptName, scriptIndex) => {
    logWarning(`Experienced an error when loading script "${scriptName}" at index ${scriptIndex}.`, error);
  };

  try {
    const {
      script,
      index,
      scriptRegistryUrl,
      scriptEnvironment,
    } = args;

    scriptNameForLog = script?.name;
    scriptIndexForLog = index;
    const scriptNameCopy = scriptNameForLog; // Probably unnecessary, but unsure.
    const scriptIndexCopy = scriptIndexForLog; // Probably unnecessary, but unsure.
    validateScript(script);

    const fetchScriptDataInputs = {
      url: scriptRegistryUrl.trim(),
      environment: scriptEnvironment.trim(),
      name: script.name.trim(),
      version: script.version.trim(),
    };

    const fetchScriptDataAction = () => fetchScriptData({
      fetchFn: fetch,
      ...fetchScriptDataInputs,
    });

    return memoizedRetryWithTimeout(
      'scriptDataCache',
      objectValuesSortedByKey(fetchScriptDataInputs),
      fetchScriptDataAction
    ).then((scriptData) => {
      const scriptTagAttributes = {
        ...scriptData.attributes,
        async: script.async ? true : undefined,
        onload: script.onload ? script.onload : undefined,
        charset: 'UTF-8',
      };

      loadScriptOnPage({
        existingScripts: getExistingScripts(),
        scriptAttributes: scriptTagAttributes,
        scriptObject: script,
      });
    }).catch((reason) => {
      handleError(reason, scriptNameCopy, scriptIndexCopy);
      reportScriptSupplierError('memoizedRetryWithTimeout', reason, pageLocale);
    });
  } catch (error) {
    handleError(error, scriptNameForLog, scriptIndexForLog);
    reportScriptSupplierError('fetchScripts', error, pageLocale);
  }
  return Promise.resolve();
}

function validateArgsAndApplyDefaults(args) {
  let errMsg = '';
  const pageLocale = args?.pageLocale || window?.scriptSupplierPageLocale;

  if (!isValidObjectButNotArray(args) && args !== undefined) {
    logWarning('Expected arguments to be passed as an object.');
  }

  const scripts = args?.scripts || window?.scriptSupplierPreset || [];
  if (!Array.isArray(scripts)
      || scripts.some((item) => !isValidObject(item))) {
    errMsg = 'Invalid script supplier config. Expected an array of objects.';
    reportScriptSupplierError('scriptsArrayCheck', errMsg, pageLocale);
    throw new Error(errMsg);
  } else {
    const scriptPreset = getPrivacySingletonObject().scriptSupplierPreset || [];
    getPrivacySingletonObject().scriptSupplierPreset = [
      ...scriptPreset,
      ...scripts,
    ];
    const presetUpdateEvent = new CustomEvent(PRESET_UPDATE_EVENT);
    setTimeout(() => {
      window.dispatchEvent(presetUpdateEvent);
    }, 0);
  }

  const scriptEnvironment = args?.scriptEnvironment || process.env.SCRIPT_SUPPLIER_SCRIPT_ENV;

  if (!isValidString(scriptEnvironment)) {
    errMsg = 'Invalid/non-existent script environment. Expected a string.';
    reportScriptSupplierError('scriptEnvCheck', errMsg, pageLocale);
    throw new Error(errMsg);
  } else {
    getPrivacySingletonObject().scriptEnvironment = scriptEnvironment;
  }

  if (!isValidString(pageLocale)) {
    errMsg = 'Invalid/non-existent page locale. Expected a string.';
    logWarning(errMsg);
    reportScriptSupplierError('invalidPageLocale', errMsg, pageLocale);
  } else {
    window.UCMPageLocale = pageLocale;
  }

  return scriptEnvironment;
}

export {
  createScriptTag,
  SCRIPT_REGISTRY_URL,
  UCM_SCRIPT_REGEX,
  HELPER_SCRIPT_REGEX,
  loadScriptOnPage,
  logError,
  processScript,
  reportScriptSupplierError,
  scriptPreviouslyLoaded,
  validateArgsAndApplyDefaults,
};
