import {
  validateArgsAndApplyDefaults,
  processScript,
  reportScriptSupplierError,
  SCRIPT_REGISTRY_URL,
  logError,
} from './helperScripts';

/**
 * User Consent Management (UCM) is responsible for displaying UI related to privacy laws including
 * the General Data Protection Regulation (GDPR).
 */
const UCMScript = {
  name: 'user-consent-management',
  version: '^1.0.0',
  async: true,
};

/**
 * Script Supplier Helper is a helper script utilized by Script Supplier to seamlessly deliver
 * scripts to a page based on local privacy laws and consent requirements as handled by
 * UCM
 */
const ScriptSupplierHelperScript = {
  name: 'dxt-script-supplier-helper',
  version: '^1.0.0',
  async: true,
};

const initialize = (argsBeforeDefaults) => {
  try {
    const scriptEnvironment = validateArgsAndApplyDefaults(argsBeforeDefaults);

    const necessaryScripts = [UCMScript, ScriptSupplierHelperScript];

    return necessaryScripts.map((script, index) => processScript({
      script,
      index,
      scriptRegistryUrl: SCRIPT_REGISTRY_URL,
      scriptEnvironment,
    }));
  } catch (error) {
    const errorMsg = 'Experienced an issue and was not able to load the requested scripts.';
    logError(errorMsg, error);
    reportScriptSupplierError('initialize', errorMsg, window?.UCMPageLocale || window?.scriptSupplierPageLocale);
  }
  return [];
};

export default initialize;
