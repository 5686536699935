import { createScriptTag } from './helperScripts';
import initialize from './initialize';

const runOnLoad = process.env.SCRIPT_SUPPLIER_ON_LOAD;

/* istanbul ignore else */
if (runOnLoad) {
  initialize();
}

export default { initialize, createScriptTag };
